

import React,{useEffect,useState} from 'react';
import {
    //Button,
    Card,
    CardBody,
    CardFooter,
    CardTitle,
    CardHeader,
    // Form,
    // Input,
    // InputGroupAddon,
    // InputGroupText,
    // InputGroup,
    Container,
    Row,
    Collapse
  } from "reactstrap";



import IntlMessages from 'lngProvider/IntlMessages';
import config from 'config';
import { getSubmissionByActivityIdApi } from 'api/submission';
  
export default function SubmissionSection({activity}) {
    ////console.log("activity :" ,activity);


    const [submissionList, setSubmissionList] = useState([]);
    const [load, setLoad] = useState(false);
    const [error, setError] = useState('');

    const [collapse, setCollapse] = useState(0);



    useEffect(async() => {
        await getSubmissionByActivityIdApi(activity.id)
            .then(res => {
                setSubmissionList(res.data);
                setLoad(true);
            })
            .catch(err => {
                setError(err.message);
                setLoad(true)
            })
    }, []);
    
    
    // const getFullName = (user)=>{
    //     return user.title !== '' ? `${user.title} ${user.name} ${user.surname}` :  `${user.name} ${user.surname}`;
    // }

    const toggle = (e)=> {
        let event = e.target.dataset.event;
        setCollapse(collapse === Number(event) ? 0 : Number(event))
      }

    if (load) {
    return (<>
   { error ? <div>{error.message}</div> : 
      <div className="section section-gold text-center"  style={{width:'100%'}}>
          <Container>
            <h2 className="title"><IntlMessages id="sidebar.submissions"/></h2>
         
             {   
             submissionList.map((item,index)=>
             <Row key={index} >
                <Card style={{    width: '100%', margin: '10px' , cursor:'pointer' }} key={index}>
                <CardHeader onClick={toggle} data-event={index}>{item.title} </CardHeader>
                <Collapse isOpen={collapse === index}>
                <CardBody style={{textAlign:'left'}}>
                  <div dangerouslySetInnerHTML={{ __html:  item.abstract}}></div>
                  </CardBody>
                </Collapse>
              </Card>    
              </Row>
                )
                }

               
      
          </Container>
        </div>
        }</>);
} else {
        return (
            <div>
                Loading...
            </div>
        );
    }


  }