import React,{useState,useEffect} from 'react';
import {
    Button,
    ModalBody,
    ModalHeader,
    ModalFooter,
    Modal,
    Form,
    Input,
    InputGroupAddon,
    InputGroupText,
    InputGroup,
    Container,
    Row,
    Col,
    
  } from "reactstrap";
import IntlMessages from 'lngProvider/IntlMessages';
import { postActivtyDetailMessage } from 'api/util';
import { loadCaptchaEnginge, LoadCanvasTemplate, LoadCanvasTemplateNoReload, validateCaptcha } from 'react-simple-captcha';

export default function MessageSection({activity}) {

    const [name,setName]= useState("");
    const [email,setEmail]= useState("");
    const [message,setMessage]= useState("");
    const [modal, setModal] = useState(false);
    const [code, setCode] = useState("");
    const toggle = () => setModal(!modal);

    useEffect(() => {
      loadCaptchaEnginge(6); 
    }, [])
    

const sendMessage = async()=>{


  //let user_captcha_value = document.getElementById('user_captcha_input').value;

     if (validateCaptcha(code)) {
      //   alert('Captcha Matched');

         const messageObject = {
          name,
          email, 
          message,
          activityId : activity.id
      }
      //console.log(messageObject)
      //burası apiye post edilecek mesaj sekretertyaya gidecek.
      const result = await postActivtyDetailMessage({message:messageObject});
      if(result.status)
      {
        //console.log("mesaj gönderildi.");
        setMessage('');
        setName('');
        setEmail('');
        setModal(true);
  
        setTimeout(()=>setModal(false),3000)
      }


     }

     else {
         alert('Doğrulama kodu hatalı');
     }


    
    
}

  return (
    <div className="section landing-section">
    <Container>
      <Row>
        <Col className="ml-auto mr-auto" md="8">
          <h2 className="text-center"><IntlMessages id="sendMessage.title"/></h2>
          <Form className="contact-form">
            <Row>
              <Col md="6">
                <label><IntlMessages id="sendMessage.nameSurname"/></label>
                <InputGroup>
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <i className="nc-icon nc-single-02" />
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input //placeholder="Name"
                   type="text" 
                   value={name}
                   onChange={(e)=>setName(e.target.value)}
                   />
                </InputGroup>
              </Col>
              <Col md="6">
                <label><IntlMessages id="sendMessage.email"/></label>
                <InputGroup>
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <i className="nc-icon nc-email-85" />
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input //placeholder="Email"
                   type="text" 
                   value={email}
                   onChange={(e)=>setEmail(e.target.value)}
                   />
                </InputGroup>
              </Col>
            </Row>
            <Row>
            <Col md="6">
              
              <div style={{marginTop:20}} >
              <LoadCanvasTemplate reloadText="Doğrulama kodu yenile" />

              </div>

            


            </Col>
            <Col md="6">
            <label>Doğrulama Kodu</label>
                <InputGroup>
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <i className="nc-icon nc-code" />
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input //placeholder="Email"
                   type="text" 
                   value={code}
                   onChange={(e)=>setCode(e.target.value)}
                   />
                </InputGroup>
            </Col>
            </Row>
            <label><IntlMessages id="sendMessage.message"/></label>
            <Input
           //   placeholder="Tell us your thoughts and feelings..."
              type="textarea"
              rows="4"
              value={message}
              onChange={(e)=>setMessage(e.target.value)}
            />
            <Row>
              <Col className="ml-auto mr-auto" md="4">
                <Button className="btn-fill" color="danger" size="lg" onClick={sendMessage}>
                <IntlMessages id="sendMessage.sendMessage"/>
                </Button>
              </Col>
            </Row>
          </Form>
        </Col>
      </Row>

      <Modal isOpen={modal} toggle={toggle}>
        <ModalBody>
          <h3>
          <IntlMessages id="sendMessage.sendMessage.success"/>
          </h3>
    
        </ModalBody>
      </Modal>

    </Container>
  </div>

  );
}
