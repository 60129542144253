import React from 'react';
import {
    Button,
    Container,
    Row,
    Col,
    
  } from "reactstrap";
  import IntlMessages from "lngProvider/IntlMessages";
import config from 'config';

export default function WebAttachmentSection({activityAttachment}) {

    
  const downloadAttachment = (type)=>{
    const item = activityAttachment.find(x=>x.attachmentType===type);

    if(item !== undefined)
    {
      
      ////console.log("itemmmmm ",item);
 

      const link = document.createElement('a');
      link.href = config.apiUrl + item.filePath ;
      link.target="_blank"
      link.setAttribute('download',item.fileName);
    //  document.body.appendChild(link);
      link.click();

    }
  }

  return (
    <div id="webAttachment" className="section text-center" style={{backgroundColor:"#ebf6fa"}}>
        <Container>
      <Row  className="mt-5">
              <Col md="3">
                <div className="info">
                  <div className="icon icon-info">
                    <i className="nc-icon nc-bullet-list-67" />
                  </div>
                  <div className="description">
                    <h4 className="info-title"><IntlMessages id="attachmentType.2"/></h4>
                    <p className="description">
                    <IntlMessages id="attachmentType.decription.2"/>
                    </p>
                    <Button className="btn-link" color="info"                   type="button"
 onClick={()=>downloadAttachment(2)}>
                    <IntlMessages id="button.download"/>
                    </Button>
                  </div>
                </div>
              </Col>
              <Col md="3">
                <div className="info">
                  <div className="icon icon-info">
                    <i className="nc-icon nc-badge" />
                  </div>
                  <div className="description">
                    <h4 className="info-title"><IntlMessages id="attachmentType.3"/></h4>
                    <p>
                    <IntlMessages id="attachmentType.decription.3"/>

                    </p>
                    <Button className="btn-link" color="info"                   type="button"
  onClick={()=>downloadAttachment(3)}>
                    <IntlMessages id="button.download"/>
                    </Button>
                  </div>
                </div>
              </Col>
              <Col md="3">
                <div className="info">
                  <div className="icon icon-info">
                    <i className="nc-icon nc-single-copy-04" />
                  </div>
                  <div className="description">
                    <h4 className="info-title"><IntlMessages id="attachmentType.4"/></h4>
                    <p>
                    <IntlMessages id="attachmentType.decription.4"/>

                    </p>
                    <Button className="btn-link" color="info"   type="button"  onClick={()=>downloadAttachment(4)}>
                    <IntlMessages id="button.download"/>
                    </Button>
                  </div>
                </div>
              </Col>
              <Col md="3">
                <div className="info">
                  <div className="icon icon-info">
                    <i className="nc-icon nc-album-2" />
                  </div>
                  <div className="description">
                    <h4 className="info-title"><IntlMessages id="attachmentType.5"/></h4>
                    <p>
                    <IntlMessages id="attachmentType.decription.5"/>

                    </p>
                    <Button className="btn-link" color="info"   type="button"  onClick={(e)=>{ e.preventDefault(); downloadAttachment(5)}}>
                    <IntlMessages id="button.download"/>
                    </Button>
                  </div>
                </div>
              </Col>
            </Row>
            </Container>
    </div>
  );
}
