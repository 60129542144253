import React from 'react';
import {
    NavItem,
    NavLink,
    Nav,
    Pagination,
    PaginationItem,
    PaginationLink,
    Progress,
    TabContent,
    TabPane,
    Container,
    Alert,
    Col
  } from "reactstrap";
import IntlMessages from 'lngProvider/IntlMessages';
import ListActivity from './ListActivity';
import {connect} from 'react-redux';

function TabActivty({listActivity,updateActivityList,locale}) {

    const [activeTab, setActiveTab] = React.useState("1");
  //  //console.log("listActivity ",listActivity)

    const nowList = listActivity.filter(item=>item.dateType===1);
    const pastList = listActivity.filter(item=>item.dateType===2);
    const futureList = listActivity.filter(item=>item.dateType===3);

    // const [nowActivityList, setNowActivityList] = React.useState(nowList);
    // const [pastActivityList, setPostActivityList] = React.useState(pastList);
    // const [futureActivityList, setFutureActivityList] = React.useState(futureList);

    // //console.log("pastActivityList ",pastActivityList)


    const toggle = tab => {
      if (activeTab !== tab) {
        setActiveTab(tab);
      } 
    };

  return (
    <Col md="12">
    <div className="title">
      <h3 className="text-center"> <IntlMessages id="activities"/></h3>
    </div>
    <div className="nav-tabs-navigation">
      <div className="nav-tabs-wrapper" style={{cursor:"pointer"}}>
        <Nav id="tabs" role="tablist" tabs>
          <NavItem>
            <NavLink
              className={activeTab === "1" ? "active font-weight-bold" : ""}
              onClick={() => {
                toggle("1");
              }}
            >
                      <IntlMessages id="activity.now"/>
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={activeTab === "2" ? "active font-weight-bold" : ""}
              onClick={() => {
                toggle("2");
              }}
            >
              <IntlMessages id="activity.future"/>
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={activeTab === "3" ? "active font-weight-bold" : ""}
              onClick={() => {
                toggle("3");
              }}
            >
                      <IntlMessages id="activity.past"/>

            </NavLink>
          </NavItem>
        </Nav>
      </div>
    </div>
    <TabContent activeTab={activeTab} className="">
      <TabPane tabId="1">
        {nowList.length > 0 ?
        <ListActivity color="success" listActivity={nowList} listActivity2={nowList}  lang={locale.locale}/>
        :    <Alert color="info">
        <Container>
          <span> <IntlMessages id="activityList.now.empty"/></span>
        </Container>
      </Alert>}
      </TabPane>
      <TabPane tabId="2">
      {futureList.length > 0 ?
           <ListActivity color="info" listActivity={futureList} listActivity2={futureList} lang={locale.locale}/>

        :    <Alert color="info">
        <Container>
          <span> <IntlMessages id="activityList.future.empty"/></span>
        </Container>
      </Alert>}
      </TabPane>
      <TabPane tabId="3">
      {pastList.length > 0 ?
        <ListActivity color="danger" listActivity={pastList} listActivity2={pastList} lang={locale.locale} />

        :    <Alert color="danger">
        <Container>
          <span> <IntlMessages id="activityList.past.empty"/></span>
        </Container>
      </Alert>}
      </TabPane>
    </TabContent>
  </Col>
  );
}

const mapStateToProps = ({settings}) => {
  const {locale } = settings;
  return { locale }
};

export default connect(mapStateToProps)(TabActivty);
