
import React , { useState, useEffect } from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Route, Redirect, Switch } from "react-router-dom";

// styles
import "assets/css/bootstrap.min.css";
import "assets/scss/paper-kit.scss";
import "assets/demo/demo.css";
import "assets/css/flag/sprite-flags-24x24.css";
// pages
import Index from "views/Index.js";
import NucleoIcons from "views/NucleoIcons.js";
import LandingPage from "views/examples/LandingPage.js";
import ProfilePage from "views/examples/ProfilePage.js";
import RegisterPage from "views/examples/RegisterPage.js";
import DetailActivity from "views/activity/DetailActivity";

import { getActivityWebAddressAllApi } from "api/activity";
// others

import configureStore from './store';
import {Provider} from 'react-redux';
import ConnectedIntlProvider from "ConnectedIntlProvider";

// const [data, setData] = useState();

// useEffect(async () => {
//   const result = await getActivityFullByIdApi();
//   setData(result.data);
// });

export const store = configureStore();

class App extends React.Component {

  constructor(){
    super();
    this.state ={
      data:[]
    }
  }

   componentDidMount = async()=>{
    const result = await getActivityWebAddressAllApi();
    //console.log("result ",result)
      this.setState({data:result.data});
  }

  
   render () {

    const tmp = this.state.data.map(activity=>{ return (<Route key={activity.id}  path={`/${activity.webAddress}`}  render={props => <DetailActivity {...props} activity={activity} />}  /> )} );

    //s//console.log(tmp);

    // //console.log("this props ",this.props)
    // const { locale } = this.props;

    // //console.log("render locale : ",locale);


    return (
      <Provider store={store}>
      <ConnectedIntlProvider >
      <BrowserRouter>
           
      <Switch>
        <Route path="/index" render={props => <Index {...props} />} />

        <Route path="/" exact render={props => <Index {...props} />} />

        <Route
          path="/nucleo-icons"
          render={props => <NucleoIcons {...props} />}
        />
        <Route
          path="/landing-page"
          render={props => <LandingPage {...props} />}
        />
        <Route
          path="/profile-page"
          render={props => <ProfilePage {...props} />}
        />
        <Route
          path="/register-page"
          render={props => <RegisterPage {...props} />}
        />
         <Route
          path="/detail-activity/:id"
          render={props => <DetailActivity {...props} />}
        />
     
      {tmp}
  
    
{/* 
       <Redirect to="/index" /> */}
      </Switch>
    </BrowserRouter>
    </ConnectedIntlProvider >
    </Provider>
    )
  }
}


// const mapStateToProps = ({ settings} ,state) => {
//     const { locale } = settings;
//     //console.log("state ",state)
//     return { locale};
//   };
  
  export default App;



ReactDOM.render(
 <App />,
  document.getElementById("root")
);


// ReactDOM.render(
//   <BrowserRouter>
//     <Switch>
//       <Route path="/index" render={props => <Index {...props} />} />
//       <Route
//         path="/nucleo-icons"
//         render={props => <NucleoIcons {...props} />}
//       />
//       <Route
//         path="/landing-page"
//         render={props => <LandingPage {...props} />}
//       />
//       <Route
//         path="/profile-page"
//         render={props => <ProfilePage {...props} />}
//       />
//       <Route
//         path="/register-page"
//         render={props => <RegisterPage {...props} />}
//       />
//        <Route
//         path="/detail-activity/:id"
//         render={props => <DetailActivity {...props} />}
//       />

//           {/* { data.map(activity=>{
//             return    <Route
//                           path={`/${activity.webAddress}`}
//                           render={props => <DetailActivity {...props} activity={activity}/>}
//                         />
//           })} */}

//       <Redirect to="/index" />
//     </Switch>
//   </BrowserRouter>,
//   document.getElementById("root")
// );
