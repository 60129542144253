import axios from 'axios';
import config from 'config';



export const getActivityHtmlApi = async()=>{
   const url = `${config.apiUrl}/v1/activityHtml/`;
   return await axios.get(url)
   .then(response => response.data).catch(err => {  return { status:false , error:err.response}  });
}
 export const getActivityHtmlByActivityIdApi = (activityId)=>{
    const url = `${config.apiUrl}/v1/activityHtml/byActivityId/${activityId}`;
    return axios.get(url)
    .then(response => response.data);
 }
export const deleteActivityHtmlByIdApi = (activityHtmlId)=>{
   const url = `${config.apiUrl}/v1/activityHtml/${activityHtmlId}`;
   return axios.delete(url)
   .then(response => response.data);
}
export const postSaveActivityHtmlApi = (activityHtml,lang)=>{
    const url = `${config.apiUrl}/v1/activityHtml/?lang=${lang}`;
    return axios.post(url,activityHtml)
    .then(response => response.data);
 }
 export const putUpdateActivityHtmlApi = (activityHtml)=>{
    const url = `${config.apiUrl}/v1/activityHtml/`;
    return axios.put(url,activityHtml)
    .then(response => response.data);
 }

 
