/*!

=========================================================
* Paper Kit React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-kit-react

* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/paper-kit-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
/*eslint-disable*/
import React from "react";

// reactstrap components
import { Row, Container } from "reactstrap";
import IntlMessages from "lngProvider/IntlMessages";

function DemoFooter() {
  return (
    <footer className="footer footer-black footer-white">
      <Container>
        <Row>
          <nav className="footer-nav">
            <ul>
              <li>
                <a
                  href="https://www.ayk.gov.tr"
                  target="_blank"
                >
                 AYK
                </a>
              </li>
              <li>
                <a
                  href="https://www.atam.gov.tr"
                  target="_blank"
                >
                  ATAM
                </a>
              </li>
              <li>
                <a
                  href="https://www.akmb.gov.tr"
                  target="_blank"
                >
                  AKMB
                </a>
              </li>
              <li>
                <a
                  href="https://www.tdk.gov.tr"
                  target="_blank"
                >
                  TDK
                </a>
              </li>
              <li>
                <a
                  href="https://www.ttk.gov.tr"
                  target="_blank"
                >
                  TTK
                </a>
              </li>
            </ul>
          </nav>
          <div className="credits ml-auto">
            <span className="copyright">
              © {new Date().getFullYear()},           <IntlMessages id="footer.copright"/>
{/* {" "}
              <i className="fa fa-heart heart" /> by AYK */}
            </span>
          </div>
        </Row>
      </Container>
    </footer>
  );
}

export default DemoFooter;
