import axios from 'axios';
import config from 'config';



 export const getActivityAttachemntByActivityIdApi = async(activityId)=>{
    const url = `${config.apiUrl}/v1/activityAttachment/byActivityId/${activityId}`;
    return await axios.get(url)
    .then(response => response.data)
 }
