import React from 'react';
import {
    Card,

    Container,
    Row,
    Col,
    
  } from "reactstrap";
export default function CenterMessageItem({message}) {
  return (
    <div className="main">
    <div className="section text-center">
    <Container>
    <Row>
      <Col className="ml-auto mr-auto" lg="6">
        <Card className="ml-auto mr-auto bg-success p-3">
          <h2 className="title mx-auto text-white">{message}</h2>
          </Card>
          </Col>
          </Row>
          </Container>
          </div>
          </div>
  );
}
