/*!

=========================================================
* Paper Kit React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-kit-react

* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/paper-kit-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

// reactstrap components

// core components
import IndexNavbar from "components/Navbars/IndexNavbar.js";
import IndexHeader from "components/Headers/IndexHeader.js";
import DemoFooter from "components/Footers/DemoFooter.js";

import React, { Component } from 'react'
import TabActivty from "./activity/TabActivity";
import { getActivityAllByDateTypeApi } from "api/activity";

export default class Index extends Component {

  constructor(){
    super();
    this.state = {
      listActivity:[],
      listActivity2:[]
    }
  }
  async componentDidMount(){
    const result = await getActivityAllByDateTypeApi();
    if(result.status)
    this.setState({listActivity : result.data, listActivity2 : result.data});
  }


  updateActivityList = (listActivity)=> {
    this.setState({listActivity})
  }

  render() {

    document.documentElement.classList.remove("nav-open");

  // React.useEffect(() => {
  //   document.body.classList.add("index"); 
  //   return function cleanup() {
  //     document.body.classList.remove("index");
  //   };
  // });

    return ( 
      <>
        <IndexNavbar />
        <IndexHeader />
        <div className="main">
      <TabActivty listActivity={this.state.listActivity2} updateActivityList={this.updateActivityList}/>

          {/* <ListActivity listActivity={this.state.listActivity} listActivity2={this.state.listActivity2} updateActivityList={this.updateActivityList}></ListActivity> */}
          {/* <SectionButtons />
          <SectionNavbars />
          <SectionNavigation />
          <SectionProgress />
          <SectionNotifications />
          <SectionTypography />
          <SectionJavaScript />
          <SectionCarousel />
          <SectionNucleoIcons />
          <SectionDark />
          <SectionLogin />
          <SectionExamples />
          <SectionDownload /> */}
          <DemoFooter />
        </div>
      </>
    );
  }
}


// function Index() {
//   document.documentElement.classList.remove("nav-open");
//   React.useEffect(() => {
//     document.body.classList.add("index");
//     return function cleanup() {
//       document.body.classList.remove("index");
//     };
//   });
//   return (
//     <>
//       <IndexNavbar />
//       <IndexHeader />
//       <div className="main">
//         <ListActivity listActivity={listActivity}></ListActivity>
//         <SectionButtons />
//         <SectionNavbars />
//         <SectionNavigation />
//         <SectionProgress />
//         <SectionNotifications />
//         <SectionTypography />
//         <SectionJavaScript />
//         <SectionCarousel />
//         <SectionNucleoIcons />
//         <SectionDark />
//         <SectionLogin />
//         <SectionExamples />
//         <SectionDownload />
//         <DemoFooter />
//       </div>
//     </>
//   );
// }

//export default Index;
