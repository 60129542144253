import axios from 'axios';
import config from 'config';


export const postActivtyDetailMessage = (message)=>{
    const url = `${config.apiUrl}/v1/public/activityDetailMessage/`;
    return axios.post(url,message)
    .then(response => response.data);
 }

 
