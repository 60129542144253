import React from 'react';
import { Container, Row,Col,Card,CardBody,CardTitle,Button,CardFooter, UncontrolledPopover, PopoverHeader, PopoverBody } from 'reactstrap';
import IntlMessages from "lngProvider/IntlMessages";
import config from 'config';
export default function SecreteriatSection({secretariatList}) {
  return (
    <div className="section section-dark text-center">
    <Container>
      <h2 className="title"><IntlMessages id="activity.secretariat"/></h2>
      <Row>
{
    secretariatList.map(user=>
    
        <Col md={secretariatList.length=== 1 ? "12": secretariatList.length=== 2 ? "6" : secretariatList.length=== 3 ? "4" : secretariatList.length=== 4 ? "3":"6" }>
          <Card className="card-profile card-plain"> 
            <div className="card-avatar">
              <a href="#pablo" onClick={e => e.preventDefault()}>
                <img alt='aaa'
                    src={config.userAuthApiUrl + user.profileImageUrl}
                    onError={(e)=>{e.target.onerror = null; e.target.src=config.userAuthApiUrl + config.defaultProfileImageUrl }}
                />
              </a>
            </div>
            <CardBody>
              <a href="#pablo" onClick={e => e.preventDefault()}>
                <div className="author">
    <CardTitle tag="h4">{user.title + ' '+user.name+' '+user.surname}</CardTitle>
    <h6 className="card-category" style={{textTransform:"none"}}>{user.inst === "YK" ? <IntlMessages id="institution.YK"/>  : user.inst === "AKM" ?
    <IntlMessages id="institution.AKM"/> : user.inst ==="ATAM" ?
     <IntlMessages id="institution.ATAM"/> :   user.inst ==="TDK" ? 
     <IntlMessages id="institution.TDK"/> : user.inst ==="TTK" ?
     <IntlMessages id="institution.TTK"/> : user.inst
     
     }</h6>
                </div> 
              </a>
         
            </CardBody>
            <CardFooter className="text-center">
            <Button
                className="btn-round mr-1"
                color="danger"
                id={"tooltip"+user.id}
                outline
                onClick={e => e.target.focus()}
              >
                <IntlMessages id="user.address"/>
              </Button>
              <UncontrolledPopover
                placement="top"
                target={"tooltip"+user.id}
                trigger="focus"
              >
                <PopoverHeader><IntlMessages id="user.address"/></PopoverHeader>
                <PopoverBody>
                 {user.address + ' '+user.city}

                 <p className="card-description text-center">
                {user.email}
              </p>
              
                </PopoverBody>
              </UncontrolledPopover>

              {/* <Button
                className="btn-just-icon btn-neutral"
                color="link"
                href="#pablo"
                onClick={e => e.preventDefault()}
              >
                <i className="fa fa-twitter" />
              </Button>
              <Button
                className="btn-just-icon btn-neutral ml-1"
                color="link"
                href="#pablo"
                onClick={e => e.preventDefault()}
              >
                <i className="fa fa-google-plus" />
              </Button>
              <Button
                className="btn-just-icon btn-neutral ml-1"
                color="link"
                href="#pablo"
                onClick={e => e.preventDefault()}
              >
                <i className="fa fa-linkedin" />
              </Button> */}
            </CardFooter>
          </Card>
        </Col>)
}

        {/* <Col md="4">
          <Card className="card-profile card-plain">
            <div className="card-avatar">
              <a href="#pablo" onClick={e => e.preventDefault()}>
                <img
                  alt="..."
                  src={require("assets/img/faces/clem-onojeghuo-3.jpg")}
                />
              </a>
            </div>
            <CardBody>
              <a href="#pablo" onClick={e => e.preventDefault()}>
                <div className="author">
                  <CardTitle tag="h4">Henry Ford</CardTitle>
                  <h6 className="card-category">Product Manager</h6>
                </div>
              </a>
              <p className="card-description text-center">
                Teamwork is so important that it is virtually impossible
                for you to reach the heights of your capabilities or make
                the money that you want without becoming very good at it.
              </p>
            </CardBody>
            <CardFooter className="text-center">
              <Button
                className="btn-just-icon btn-neutral"
                color="link"
                href="#pablo"
                onClick={e => e.preventDefault()}
              >
                <i className="fa fa-twitter" />
              </Button>
              <Button
                className="btn-just-icon btn-neutral ml-1"
                color="link"
                href="#pablo"
                onClick={e => e.preventDefault()}
              >
                <i className="fa fa-google-plus" />
              </Button>
              <Button
                className="btn-just-icon btn-neutral ml-1"
                color="link"
                href="#pablo"
                onClick={e => e.preventDefault()}
              >
                <i className="fa fa-linkedin" />
              </Button>
            </CardFooter>
          </Card>
        </Col> */}
        {/* <Col md="4">
          <Card className="card-profile card-plain">
            <div className="card-avatar">
              <a href="#pablo" onClick={e => e.preventDefault()}>
                <img
                  alt="..."
                  src={require("assets/img/faces/joe-gardner-2.jpg")}
                />
              </a>
            </div>
            <CardBody>
              <a href="#pablo" onClick={e => e.preventDefault()}>
                <div className="author">
                  <CardTitle tag="h4">Sophie West</CardTitle>
                  <h6 className="card-category">Designer</h6>
                </div>
              </a>
              <p className="card-description text-center">
                A group becomes a team when each member is sure enough of
                himself and his contribution to praise the skill of the
                others. No one can whistle a symphony. It takes an
                orchestra to play it.
              </p>
            </CardBody>
            <CardFooter className="text-center">
              <Button
                className="btn-just-icon btn-neutral"
                color="link"
                href="#pablo"
                onClick={e => e.preventDefault()}
              >
                <i className="fa fa-twitter" />
              </Button>
              <Button
                className="btn-just-icon btn-neutral ml-1"
                color="link"
                href="#pablo"
                onClick={e => e.preventDefault()}
              >
                <i className="fa fa-google-plus" />
              </Button>
              <Button
                className="btn-just-icon btn-neutral ml-1"
                color="link"
                href="#pablo"
                onClick={e => e.preventDefault()}
              >
                <i className="fa fa-linkedin" />
              </Button>
            </CardFooter>
          </Card>
        </Col>
        <Col md="4">
          <Card className="card-profile card-plain">
            <div className="card-avatar">
              <a href="#pablo" onClick={e => e.preventDefault()}>
                <img
                  alt="..."
                  src={require("assets/img/faces/erik-lucatero-2.jpg")}
                />
              </a>
            </div>
            <CardBody>
              <a href="#pablo" onClick={e => e.preventDefault()}>
                <div className="author">
                  <CardTitle tag="h4">Robert Orben</CardTitle>
                  <h6 className="card-category">Developer</h6>
                </div>
              </a>
              <p className="card-description text-center">
                The strength of the team is each individual member. The
                strength of each member is the team. If you can laugh
                together, you can work together, silence isn’t golden,
                it’s deadly.
              </p>
            </CardBody>
            <CardFooter className="text-center">
              <Button
                className="btn-just-icon btn-neutral"
                color="link"
                href="#pablo"
                onClick={e => e.preventDefault()}
              >
                <i className="fa fa-twitter" />
              </Button>
              <Button
                className="btn-just-icon btn-neutral ml-1"
                color="link"
                href="#pablo"
                onClick={e => e.preventDefault()}
              >
                <i className="fa fa-google-plus" />
              </Button>
              <Button
                className="btn-just-icon btn-neutral ml-1"
                color="link"
                href="#pablo"
                onClick={e => e.preventDefault()}
              >
                <i className="fa fa-linkedin" />
              </Button>
            </CardFooter>
          </Card>
        </Col> */}
      </Row>
    </Container>
  </div>
  );
}
