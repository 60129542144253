import axios from 'axios';
import config from 'config';



 export const getSubmissionByActivityIdApi = (activityId)=>{
    const url = `${config.apiUrl}/v1/public/submission/byActivityId/${activityId}`;
    return axios.get(url)
    .then(response => response.data);
 }
