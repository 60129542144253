

import React,{useEffect} from 'react';
import {
    Button,
    Card,
    CardBody,
    CardFooter,
    CardTitle,
    Container,
    Row,
    Col
  } from "reactstrap";
import IntlMessages from 'lngProvider/IntlMessages';
import config from 'config';
  
export default function HTMLSection({activityHtml,lang}) {
   

  return (

      <div id={"activityHtml"+activityHtml.id} className={`section text-center text-${activityHtml.textColor}`}>
          <Container>
            <h2 className="title">   <IntlMessages id={"attachmentType."+activityHtml.title}/></h2>
            <Row>
              <div
            //    className={`section-${activityHtml.color} text-center`}
                style={{backgroundColor:activityHtml.color , color:activityHtml.textColor , width:'100%'}}
                dangerouslySetInnerHTML={{__html: lang ==="en" ? activityHtml.htmlEn : activityHtml.html}}>
              </div>
           {/* <Card >
              
                  <CardBody
                 
                  
                  </CardBody>
         
                </Card> */}
             
            </Row>
          </Container>
        </div>

  );
}
