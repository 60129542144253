
import React,{useState,useEffect} from "react";

// reactstrap components
import {

  Container,
  Row,
  Col,
  Spinner,
  
} from "reactstrap";

// core components 

import DemoFooter from "components/Footers/DemoFooter.js";
import DetailActivityHeder from "components/Headers/DetailActivityHeder";
import DetailActivityNavbar from "components/Navbars/DetailActivityNavbar";
import { getActivityFullByIdApi } from "api/activity";
import IntlMessages from "lngProvider/IntlMessages";
import { getActivityAttachemntByActivityIdApi } from "api/activityAttachment";
import config from "config";
import Comitteee from "./Committee";
import SubmissionSection from "./SubmissionSection";
import ActivityImagesCarousel from "./ActivityImagesCarousel";
import MessageSection from "./MessageSection";
import { getActivityHtmlByActivityIdApi } from "api/activityHtml";
import HTMLSection from "./HTMLSection";
import {connect} from 'react-redux';
import SecreteriatSection from "./SecreteriatSection";
import WebAttachmentSection from "./WebAttachmentSection";
import CenterMessageItem from "./CenterMessageItem";

function DetailActivity(props) {
  ////console.log("detail propsss ",props);
  const [isLoading, setIsLoading] = useState(false);

  const loadingStyle={
    marginLeft: "auto" ,
    marginRight: "auto",
    textAlign: "center",
    display: "block",
    marginTop: "400px",
    width: "80px",
    height: "80px"
  }

  const [activity, setActivity] = useState({
    title:'',
    startDateTime:new Date(),
    endDateTime:new Date(),
    committeeTypeList:[],
    secretariatList:[],
    institution:'',
    id:0
  });

  const [activityAttachment,setActivityAttachment]= useState([]);
  const [activityHtmlList,setActivityHtmlList]= useState([]);

  

  document.documentElement.classList.remove("nav-open");

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      if(props.activity !== undefined)
      {
        const result = await getActivityFullByIdApi(props.activity.id);

        setActivity(result.data);
        document.title = result.data.title;

        const res = await getActivityAttachemntByActivityIdApi(props.activity.id)
        if(res.status)
        setActivityAttachment(res.data);

        const res1 = await getActivityHtmlByActivityIdApi(props.activity.id)
        if(res1.status)
        setActivityHtmlList(res1.data);
      }
      setIsLoading(false);

    
      document.body.classList.add("profile-page");
      return function cleanup() {
        document.body.classList.remove("profile-page");
      };


  

    };
    fetchData();
  }, []);

  // React.useEffect(async() => {

  //   const result = await getActivityFullByIdApi(props.match.params.id);
  //   //console.log("result ",result)
  //   setActivity(result.data)
  //   document.body.classList.add("profile-page");
  //   return function cleanup() {
  //     document.body.classList.remove("profile-page");
  //   };
  // });

//console.log("activity",activity)
  return (
    
    <>
  {isLoading ? (
        <div>
          <Spinner size="xl" animation="border" role="status" style={loadingStyle}>
  {/* <span className="sr-only">Loading...</span> */}
</Spinner>
        </div>
      ) : activity.status === 1 ?
     <CenterMessageItem message={ <IntlMessages id="activity.onepage.notView"/>} />
    : (
        <div>
          
          <DetailActivityNavbar activity={activity} activityHtmlList={activityHtmlList}/>

     <DetailActivityHeder activity={activity} activityAttachment={activityAttachment} lang={props.locale.locale}/>

     <WebAttachmentSection  activityAttachment={activityAttachment} />


      <div className="main">
        <div className="section-nude text-center">
          <Container>
            <Row>
              <Col className="ml-auto mr-auto" md="6">
                <h2 className="title" style={{fontWeight:400}}> <IntlMessages id="activity.purpose"/></h2>
                <h5 className="description" style={{fontWeight:400 , textAlign:'justify'}}>
                 {props.locale.locale === "en" ? activity.purposeEn :  activity.purpose}
                </h5>
                <br />
        
              </Col>
              <Col className="ml-auto mr-auto" md="6">
                <h2 className="title" style={{fontWeight:400}}><IntlMessages id="activity.subject"/></h2>
                <h5 className="description" style={{fontWeight:400, textAlign:'justify'}} >
            
                {props.locale.locale === "en" ? activity.subjectEn :  activity.subject}
                </h5>
                <br />
    
              </Col>
            </Row>
           </Container>
        </div>
        
        {activityHtmlList.map(activityHtml=><HTMLSection key={activityHtml.id} activityHtml={activityHtml} lang={props.locale.locale}/>  )}
        <ActivityImagesCarousel activityAttachment={activityAttachment} />
        {activity.viewSubmission === 1 ?<SubmissionSection activity={activity} /> : null }

  
        <Comitteee activity={activity} />
    
        {activity.secretariatList.length>0 ? <SecreteriatSection secretariatList={activity.secretariatList} /> : null}
        <MessageSection activity={activity}></MessageSection>

        </div>
      <DemoFooter /> 
        </div>
      )}

    </>
  );
}

//export default DetailActivity;
const mapStateToProps = ({settings}) => {
  const {locale } = settings;
  return { locale }
};

export default connect(mapStateToProps)(DetailActivity);