
  
import React  from "react";


// styles
import "assets/css/bootstrap.min.css";
import "assets/scss/paper-kit.scss";
import "assets/demo/demo.css";
import "assets/css/flag/sprite-flags-24x24.css";

// others
import {IntlProvider} from 'react-intl'
import AppLocale from './lngProvider';

import { connect } from "react-redux";




  const ConnectedIntlProvider = ({ children, ...props }) => {
      
    const {locale} = props;
const currentAppLocale = AppLocale[locale.locale];
////console.log(" currentAppLocale ",currentAppLocale)

////console.log("render locale : ",locale);

    return( // the props contain the messages and the locale
    <IntlProvider 
     locale={currentAppLocale.locale}
    messages={currentAppLocale.messages} >
    { children }
    </IntlProvider>
  )};
  

const mapStateToProps = ({settings}) => {
    ////console.log("Gelen auth : " , auth)
    const { locale} = settings;

    return {locale}
  };

  export default connect(mapStateToProps)(ConnectedIntlProvider);
  