import {

  SWITCH_LANGUAGE,

} from 'constants/ActionTypes';
////console.log("languageeeeeeeeeeeeee ",localStorage.getItem("language") )
const rltLocale = ['ar'];
const initialSettings = {
  locale: localStorage.getItem("language") !== null ? JSON.parse(localStorage.getItem("language")) : {
    languageId: 'turkish',
    locale: 'tr',
    name: 'Turkish',
    icon: 'tr'
  }
};

const settings = (state = initialSettings, action) => {
  switch (action.type) {
    case '@@router/LOCATION_CHANGE':
      return {
        ...state,
        navCollapsed: false
      };
    case SWITCH_LANGUAGE:
      return {
        ...state,
        locale: action.payload,
        isDirectionRTL: rltLocale.includes(action.payload.locale)
      };

    default:
      return state;
  }
};

export default settings;
