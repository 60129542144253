
import React from "react";

// reactstrap components
import { Button, Container, Col, Row,Badge } from "reactstrap";
import IntlMessages from "lngProvider/IntlMessages";
import config from "config";
import { getDateLocaleShort } from "utilFunction";
import useWindowDimensions from "components/Navbars/useWindowDimensions";


// core components

function DetailActivityHeder({activity,activityAttachment,lang}) {
  let pageHeader = React.createRef();
  const startDate = getDateLocaleShort(lang,activity.startDateTime);
  //console.log("startdate ", startDate)

  const { height, width } = useWindowDimensions();

  const isHaveBanner = activityAttachment.some(x=>x.attachmentType===1);
//console.log("is ahve banner ",isHaveBanner)
  React.useEffect(() => {
    if (window.innerWidth < 991) {
      const updateScroll = () => {
        let windowScrollTop = window.pageYOffset / 3;
        pageHeader.current.style.transform =  "translate3d(0," + windowScrollTop + "px,0)";
      };
      window.addEventListener("scroll", updateScroll);
      return function cleanup() {
        window.removeEventListener("scroll", updateScroll);
      };
    }
  });

      
  const downloadAttachment = (type)=>{
    const item = activityAttachment.find(x=>x.attachmentType===type);

    if(item !== undefined)
    {
      
      ////console.log("itemmmmm ",item);
 

      const link = document.createElement('a');
      link.href = config.apiUrl + item.filePath ;
      link.target="_blank"
      link.setAttribute('download',item.fileName);
    //  document.body.appendChild(link);
      link.click();

    }
  } 

  return (
    <>
      <div
        // style={{
        //   backgroundImage: "url(" + require("assets/img/daniel-olahh.jpg") + ")"
        // }}
        className="page-header"
        data-parallax={true}
        ref={pageHeader}
        
      >
           

        {/* <div className="filter" /> */}
       
        <Container>
        <Row  style={{marginTop:'100px'}}>
          {isHaveBanner && width>990 ?
        <Col className="ml-auto mr-auto" md="6">
 
        {
              activityAttachment.map(x=>{
                if(x.attachmentType === 1 )
                return <img alt="logo" src={config.apiUrl + x.filePath } style={{marginTop:'5%',marginBottom:'5%', maxWidth:'100%'}}></img>
              })
            }
      </Col>
        : null } 

       
        <Col className="ml-auto mr-auto align-self-center"   md={isHaveBanner && width>990 ? "6" : "12"}>
        <div className="motto text-center">

 {
   activityAttachment.some(x=>x.attachmentType===8) &&
   <img src={config.apiUrl +"images/"+activity.institution.toLowerCase()+".png" } alt="logo"
    style={width >470 ?  {margin:'30px',marginTop:'50px', width:'150px',height:'150px'} : 
    {margin:'15px',marginTop:'27%', width:'70px',height:'70px'}
    }></img>
 } 

{
  activityAttachment.map(x=>{

 if( x.attachmentType === 8 )
return <img src={config.apiUrl + x.filePath } alt="logo2"
style={width >470 ?  {margin:'30px',marginTop:'50px', width:'150px',height:'150px'} : 
{margin:'15px',marginTop:'27%', width:'70px',height:'70px'}
}></img>
  })
}
<br />
{
//  //console.log("aaaaaaaaaaaa", activity.universities)
(lang==="tr" && !activityAttachment.some(x=>x.attachmentType===8)) && (activity.universities !== undefined ?
    activity.universities.split('-').map(uni=><h3 key={uni} style={width <470 ?{margin:0,fontSize:'15px'}:null}>{uni}</h3>) : activity.universities)
}
{
//  //console.log("aaaaaaaaaaaa", activity.universities)
(lang==="en" && !activityAttachment.some(x=>x.attachmentType===8)) && ( activity.universitiesEn !== undefined ?
    activity.universitiesEn.split('-').map(uni=><h3 key={uni} style={width <470 ?{margin:0,fontSize:'15px'}:null}>{uni}</h3>) : activity.universitiesEn)
}

<h2
style={width<380 ? {fontSize:'20px',fontWeight:'bold'}: {fontWeight:'bold'} }
>{lang === "tr" ? activity.title :lang==="en" && activity.titleEn !== "" ? activity.titleEn : activity.title}</h2>
 

<h4
style={width<380 ? {fontSize:'10px'}:null }
> <IntlMessages id="activity.startDateTime"/> - <IntlMessages id="activity.endDateTime"/></h4>
<h5
style={width<380 ? {fontSize:'10px'}:null }

>{ getDateLocaleShort(lang,activity.startDateTime) } - { getDateLocaleShort(lang,activity.endDateTime) }</h5>
<br />

{
  activity.calledActivity === 1 && <h6 style={width<380 ? {fontSize:'12px',fontWeight:'bold' , textTransform:'none'}: {fontWeight:'bold',textTransform:'none'} }> <div className="text-danger"><IntlMessages id="activity.called"/></div></h6>

 
  }
                 <Button  className="btn-round mb-2 mr-3"  color="warning"               outline
 onClick={()=>downloadAttachment(2)}>
           <IntlMessages id="attachmentType.2"/>
                    </Button>


{
  activity.status === 0 && new Date(activity.deadlineAbstract) >=  new Date() ?
  <Button className="btn-round mb-2" 
 color="success" type="button" outline
 onClick={()=>{
   
  let a = document.createElement('a');
 a.href = config.loginPageUrl +  "bilsis/sendSubmission?a=" + activity.id;
a.target="_blank";
 a.click(); 
}

}
 >

<IntlMessages id="button.submission.send"/>
</Button>
: ""
}
{
  new Date(activity.deadlineAbstract) <  new Date() ?

  <Badge className="badge m-4" style={{textTransform:'none' , fontSize:15}} color="danger">
  <IntlMessages id="activity.deadline.abstract.message"/>
  </Badge>
  : null
}



{activityAttachment.some(x=>x.attachmentType===9) ?
  <Button className="btn-round ml-2 mb-2" color="neutral" type="button" outline onClick={()=>downloadAttachment(9)}>
<IntlMessages id="attachmentType.9"/>       <IntlMessages id="button.download"/>
</Button> : null

}
<br />
<br />
<h6 style={width<380 ? {fontSize:'12px',fontWeight:'bold' , textTransform:'none'}: {fontWeight:'bold',textTransform:'none'} }><IntlMessages id="activity.management.deadline.abstract"/>{" : "+ getDateLocaleShort(lang,activity.deadlineAbstract) }</h6>
<h6 style={width<380 ? {fontSize:'12px',fontWeight:'bold' , textTransform:'none'}: {fontWeight:'bold',textTransform:'none'} }><IntlMessages id="activity.management.deadline.fullText"/>{" : "+ getDateLocaleShort(lang,activity.deadlineFullText) } </h6>
<br /><br />
<span className="mt-3">
  <span className="bold"> <IntlMessages id="activity.address"/> : </span>{activity.address}
</span>

</div>

        </Col>
      </Row>
       </Container>
      </div>
    </> 
  );
}

export default DetailActivityHeder;
