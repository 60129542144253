
import React,{useEffect} from "react";

// reactstrap components
import { Table , Container, Button, Col, Badge } from "reactstrap";
import config from "config";
import IntlMessages from "lngProvider/IntlMessages";
import FilterActivity from './FilterActivity';
import { getDateLocaleShort } from "utilFunction";
import { getTwoDateMergeLocaleShort } from "utilFunction";
// core components

function ListActivity({listActivity,listActivity2,updateActivityList,color,lang}) {
  ////console.log("list activity deatail :",listActivity);

     const [newActivityList, setNewActivityList] = React.useState(listActivity);

     useEffect(() => {
      if(listActivity){ 
        setNewActivityList(listActivity)
      }
       },[listActivity]) 
       
  return (
    <>
      <div className="">
        <FilterActivity color={color} listActivity={newActivityList} listActivity2={listActivity2} updateActivityList={setNewActivityList}/>
        <Container>
        <Table striped>
      <thead>
        <tr>
          <th>#</th>
          <th><IntlMessages id="activity.title"/>
</th>
          <th><IntlMessages id="date"/></th>
          <th><IntlMessages id="activity.institution"/></th>
          <th><IntlMessages id="activity.deadline"/></th>
          <th></th>
        </tr> 
      </thead>
      <tbody>
      {
            newActivityList.map((item,index)=>{
              return   <tr key={item.id}>
              <th scope="row">{index+1}</th>
              <td>{item.title}</td>
              {/* <td style={{width:'20%'}}>{new Date(item.startDateTime).toLocaleDateString()} - {new Date(item.endDateTime).toLocaleDateString()}</td> */}
              <td style={{width:'20%'}}>{getTwoDateMergeLocaleShort(lang, item.startDateTime , item.endDateTime)}</td>

              
              <td>{item.institution}</td>
              <td>
              <Badge className="badge" color={color}>
                       {getDateLocaleShort(lang,item.deadlineAbstract)}
                       </Badge>
              </td>
              <td style={{width:'30%'}}>
              <Button
                  className="btn-round mr-1"
                  color={color}
                  outline
                  type="button"
                  //href={"/detail-activity/"+ item.id}
                  href={"/"+ item.webAddress}

                >
                    <IntlMessages id="detail"/>
                </Button>
                {
                new Date(item.deadlineAbstract) <  new Date() ?
                     <Badge className="badge" color={"warning"}>
                       <IntlMessages id="activity.deadline.abstract.message"/></Badge>
                       : 
                       
                       <Button
                       className="btn-round mr-1"
                       color="success"
                       outline
                       type="button"
                       href={ config.loginPageUrl + "bilsis/sendSubmission?a=" + item.id}
                       
                     >
                       <IntlMessages id="button.submission.send"/>
                     </Button>
                       }

              
                {/* <Button
                  className="btn-round mr-1"
                  color="danger"
                  outline
                  type="button"
                >
                  Danger
                </Button> */}

              </td>
            </tr>
            })
            }
     
      </tbody>
    </Table>


           
         
        </Container>
      </div>{" "}
    </>
  );
}

export default ListActivity;
