import enLang from './entries/en-US';
import trLang from './entries/tr_TR';
import {addLocaleData} from 'react-intl';

const AppLocale = { 
  tr: trLang,
  en: enLang,

};

addLocaleData(AppLocale.tr.data);
addLocaleData(AppLocale.en.data);


export default AppLocale;
