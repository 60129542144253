import axios from 'axios';
import config from 'config';

export const getActivityPDFByIdApi = (id,lang)=>{
   const url = `${config.apiUrl}/v1/pdf/activity/${id}?lang=${lang}`;
   return axios.get(url,{
      method: 'GET',
      responseType: 'blob',
     
   })
   .then(response => {
      const file = new Blob(
         [response.data], 
         {type: 'application/pdf'});
         return file;
   });
}

export const getWriterSendSubmissionPDFByIdApi =  (id,lang)=>{
   const url = `${config.apiUrl}/v1/pdf/writerSendSubmissionList/${id}?lang=${lang}`;
   return axios.get(url,{
      method: 'GET',
      responseType: 'blob',
     
   })
   .then(response => {
      const file = new Blob(
         [response.data], 
         {type: 'application/pdf'});
         return file;
   });
}

export const getActivityFullAllApi = async()=>{
   const url = `${config.apiUrl}/v1/activity/`;
   return await axios.get(url)
   .then(response => response.data).catch(err => {  return { status:false , error:err.response}  });
}

export const getActivityAllApi = async()=>{
   const url = `${config.apiUrl}/v1/activity/`;
   return await axios.get(url)
   .then(response => response.data).catch(err => {  return { status:false , error:err.response}  });
}
export const getManagementActivityAllApi = async(userId)=>{
   const url = `${config.apiUrl}/v1/activity/managementActivity/${userId}`;
   return await axios.get(url)
   .then(response => response.data).catch(err => {  return { status:false , error:err.response}  });
}


export const getActivityFullByIdApi = (id)=>{
   const url = `${config.apiUrl}/v1/activity/full/${id}`;
   return axios.get(url)
   .then(response => response.data).catch(err => {  return { status:false , error:err.response}  });
}



export const getActivityByActivityIdApi = (activityId)=>{
    const url = `${config.apiUrl}/v1/activity/${activityId}`;
    return axios.get(url)
    .then(response => response.data);
 }

 export const getActivityFullByUserIdApi = (userId)=>{
   const url = `${config.apiUrl}/v1/activity/full/${userId}`;
   return axios.get(url)
   .then(response => response.data).catch(err => {  return { status:false , error:err.response}  });
}

 export const postSaveActivityApi = (activity)=>{
    const url = `${config.apiUrl}/v1/activity/`;
    return axios.post(url,activity)
    .then(response => response.data);
 }

 export const putUpdateActivityApi = (activity)=>{
    const url = `${config.apiUrl}/v1/activity/`;
    return axios.put(url,activity)
    .then(response => response.data);
 }

 export const getActivityAllByDateTypeApi = async()=>{
   const url = `${config.apiUrl}/v1/activity/byDateType/all`;
   return await axios.get(url)
   .then(response => response.data).catch(err => {  return { status:false , error:err.response}  });
}


export const getActivityWebAddressAllApi = async()=>{
   const url = `${config.apiUrl}/v1/activity/webAddress/all`;
   return await axios.get(url)
   .then(response => response.data).catch(err => {  return { status:false , error:err.response}  });
}