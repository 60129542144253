


export const getDateLocaleShort = (locale,date)=> {
    return new Date(date).toLocaleString(locale,{timeStyle:'short', dateStyle:'long'}) ;
  }

  export function countWords2(s){
    s = s.replace(/(^\s*)|(\s*$)/gi,"");//exclude  start and end white-space
    s = s.replace(/[ ]{2,}/gi," ");//2 or more space to 1
    s = s.replace(/\n /,"\n"); // exclude newline with a start spacing
    return s.split(' ').filter((str)=>  str !== "").length;
  }
  
  export const getTwoDateMergeLocaleShort = (locale,date1,date2)=> {
    const d1 = new Date(date1);
    const d2 = new Date(date2);
  //  //console.log("d1.getDay() ",d1.getDay())

    if(d1.getMonth()===d2.getMonth() && d1.getFullYear()===d2.getFullYear())
    {
   //   d1.setMonth(d1.getMonth()+1);
      return d1.getDay()===d2.getDay() ? d1.toLocaleDateString(locale,{dateStyle:'long'}) : d1.toLocaleString(locale, { day: "numeric" })+"-"+d2.toLocaleString(locale, { day: "numeric" })+" "+d1.toLocaleString(locale, { month: "long" })+ " "+d1.getFullYear();
    }
    else if(d1.getMonth()!==d2.getMonth())
    {
      return  d1.toLocaleDateString(locale,{dateStyle:'long'}) + " - " +  d2.toLocaleDateString(locale,{dateStyle:'long'});
    }
   

    return "Tanımzsız" ;
  }
