
import React,{useEffect} from "react";

// reactstrap components
import {
  Card,
  Container,
  Row,
  Col,
  Carousel,
  CarouselItem,
  CarouselIndicators,
  CarouselCaption,
  CardTitle
} from "reactstrap";
import config from "config";
import IntlMessages from "lngProvider/IntlMessages";


function ActivityImagesCarousel({activityAttachment}) {
  const [activeIndex, setActiveIndex] = React.useState(0);
  const [animating, setAnimating] = React.useState(false);
  const [items, setItems] = React.useState([]);

  useEffect(() => {
    if(activityAttachment){ 
      
    setItems(activityAttachment.filter(x=>x.attachmentType === 6 ).map(item=>{
      return { src : config.apiUrl + item.filePath , allText:'AYK' , caption:item.fileName}
  }));

    }
     },[activityAttachment])




   

  ////console.log("items ",items)

  const onExiting = () => {
    setAnimating(true);
  };
  const onExited = () => {
    setAnimating(false);
  };
  const next = () => {
    if (animating) return;
    const nextIndex = activeIndex === items.length - 1 ? 0 : activeIndex + 1;
    setActiveIndex(nextIndex);
  };
  const previous = () => {
    if (animating) return;
    const nextIndex = activeIndex === 0 ? items.length - 1 : activeIndex - 1;
    setActiveIndex(nextIndex);
  };
  const goToIndex = newIndex => {
    if (animating) return;
    setActiveIndex(newIndex);
  };
  return (
    
  
      <div className="section pt-o text-center section-dark" id="carousel">
      {items.length>0 ? 
        <Container>
          <Row>
            <Col className="ml-auto mr-auto" md="12">
              <Card className="page-carousel">
                <CardTitle>
                <IntlMessages id="activity.images"/>
                </CardTitle>
                <Carousel
                  activeIndex={activeIndex}
                  next={next}
                  previous={previous}
                >
                  <CarouselIndicators
                    items={items}
                    activeIndex={activeIndex}
                    onClickHandler={goToIndex}
                  />
                  {items.map(item => {
                    return (
                      <CarouselItem
                        onExiting={onExiting}
                        onExited={onExited}
                        key={item.src}
                      >
                        <img src={item.src} alt={item.altText} />
                        <CarouselCaption
                          captionText={item.caption}
                          captionHeader=""
                        />
                      </CarouselItem>
                    );
                  })}
                  <a
                    className="left carousel-control carousel-control-prev"
                    data-slide="prev"
                    href="#pablo"
                    onClick={e => {
                      e.preventDefault();
                      previous();
                    }}
                    role="button"
                  >
                    <span className="fa fa-angle-left" />
                    <span className="sr-only"><IntlMessages id="stepper.activity.button.back"/></span>
                  </a>
                  <a
                    className="right carousel-control carousel-control-next"
                    data-slide="next"
                    href="#pablo"
                    onClick={e => {
                      e.preventDefault();
                      next();
                    }}
                    role="button"
                  >
                    <span className="fa fa-angle-right" />
                    <span className="sr-only"><IntlMessages id="stepper.activity.button.next"/></span>
                  </a>
                </Carousel>
              </Card>
            </Col>
          </Row>
        </Container>
        : null }
      </div>
    
  );
}

export default ActivityImagesCarousel;
