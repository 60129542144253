
import React from "react";
// nodejs library that concatenates strings
import classnames from "classnames";
// reactstrap components

import config from "config";
import {
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Collapse,
  NavbarBrand,
  Navbar,
  NavItem,
  NavLink,
  Nav,
  Container,
  Button
} from "reactstrap";

import {connect} from 'react-redux';
import {switchLanguage} from 'actions/Setting';
import languageData from "components/LanguageSwitcher/data";
import IntlMessages from "lngProvider/IntlMessages";

function IndexNavbar({locale,switchLanguage}) {
  const [navbarColor, setNavbarColor] = React.useState("navbar-transparent");
  const [navbarCollapse, setNavbarCollapse] = React.useState(false);

  const toggleNavbarCollapse = () => {
    setNavbarCollapse(!navbarCollapse);
    document.documentElement.classList.toggle("nav-open");
  };

  React.useEffect(() => {
    const updateNavbarColor = () => {
      if (
        document.documentElement.scrollTop > 299 ||
        document.body.scrollTop > 299
      ) {
        setNavbarColor("");
      } else if (
        document.documentElement.scrollTop < 300 ||
        document.body.scrollTop < 300
      ) {
        setNavbarColor("navbar-transparent");
      }
    };
 
    window.addEventListener("scroll", updateNavbarColor);

    return function cleanup() {
      window.removeEventListener("scroll", updateNavbarColor);
    };
  });
  return (
    <Navbar className={classnames("fixed-top", navbarColor)} expand="lg">
      <Container>
        <div className="navbar-translate">
          <NavbarBrand
            data-placement="bottom"
            style={{textTransform:'none'}}
            href="/index"
          //  target="_blank"
      //    title={ [<IntlMessages id="activity.onepage.name"/>,""]}
          >
              <IntlMessages id="activity.onepage.name"/>
          </NavbarBrand> 
          <button
            aria-expanded={navbarCollapse}
            className={classnames("navbar-toggler navbar-toggler", {
              toggled: navbarCollapse
            })}
            onClick={toggleNavbarCollapse}
          >
            <span className="navbar-toggler-bar bar1" />
            <span className="navbar-toggler-bar bar2" />
            <span className="navbar-toggler-bar bar3" />
          </button>
        </div>
        <Collapse
          className="justify-content-end"
          navbar
          isOpen={navbarCollapse}
        >
          <Nav navbar>
          {/* <NavItem>
              <NavLink
                data-placement="bottom"
                href="https://yuzuncuyil.gov.tr"
                target="_blank"
                title="Türkiye Cumhuriyeti 100. Yılı"
              >
                <img src={require("assets/img/100.png")} style={{width:'80px', height:40}} />
              </NavLink>
            </NavItem> */}

          <NavItem>
              <NavLink
                data-placement="bottom"
                href="https://www.ktb.gov.tr"
                target="_blank"
                title="T.C. Kültür ve Turizm Bakanlığı"
              >
                <img src={require("assets/img/logo/ktb_logo.png")} style={{width:'40px'}} />
              </NavLink>
            </NavItem>
  
            <NavItem>
              <NavLink
                data-placement="bottom"
                href="https://www.ayk.gov.tr"
                target="_blank"
                title="T.C. Atatürk Kültür, Dil ve Tarih Yüksek Kurumu"
              >
                <img src={require("assets/img/logo/ayk_logo.png")} style={{width:'40px'}} />
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                data-placement="bottom"
                href="https://www.atam.gov.tr"
                target="_blank"
                title="Atatürk Araştırma Merkezi Başkanlığı"
              >
                <img src={require("assets/img/logo/atam.png")} style={{width:'40px'}} />
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                data-placement="bottom"
                href="https://www.akmb.gov.tr"
                target="_blank"
                title="Atatürk Kültür Merkezi Başkanlığı"
              >
                <img src={require("assets/img/logo/akm.png")} style={{width:'40px'}} />
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                data-placement="bottom"
                href="https://www.tdk.gov.tr"
                target="_blank"
                title="Türk Dil Kurumu"
              >
                <img src={require("assets/img/logo/tdk.png")} style={{width:'40px'}} />
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                data-placement="bottom"
                href="https://www.ttk.gov.tr"
                target="_blank"
                title="Türk Tarih Kurumu"
              >
                <img src={require("assets/img/logo/ttk.png")} style={{width:'40px'}} />
              </NavLink>
            </NavItem> 
            {/* <NavItem>
              <NavLink
                data-placement="bottom"
                href="https://www.github.com/CreativeTimOfficial/paper-kit-react?ref=creativetim"
                target="_blank"
                title="Star on GitHub"
              >
                <i className="fa fa-github" />
                <p className="d-lg-none">GitHub</p>
              </NavLink>
            </NavItem> */}
            {/* <NavItem>
              <NavLink
                href="/index"
              >
                <i className="nc-icon nc-book-bookmark" />   <IntlMessages id="activities"/>
              </NavLink>
            </NavItem> */}
            <NavItem>
              <Button
                className="btn-round"
                color="danger"
                href={config.submissionManagedSystemUrl}
                target="_blank"
               style={{margin: "25px 3px"}}
              >
                  <IntlMessages id="submission.manage.system.name"/>
              </Button>
            </NavItem>

            <UncontrolledDropdown nav inNavbar>
                <DropdownToggle
              style={{margin: "25px 3px"}}
                  aria-haspopup={true}
                  caret
                  color="default"
                  data-toggle="dropdown"
                  href="#pablo"
                  id="navbarDropdownMenuLink"
                  nav
                  onClick={e => e.preventDefault()}
                >
                   <i   style={{marginBottom: "-7px"}} className={`flag flag-24 flag-${locale.icon}`}/> 
                   <p className="ml-2">{locale.locale}</p>
                   {/* Dil */}
                </DropdownToggle>
                <DropdownMenu aria-labelledby="navbarDropdownMenuLink">
 
                                                        {languageData.map((language, index) => 
                                                             <DropdownItem
                                                             key={index}
                                                             href="#"
                                                             onClick={
                                                               (e)=>{
                                                                e.preventDefault();
                                                                localStorage.setItem("language",JSON.stringify(language));
                                                                switchLanguage(language);
                                                               }
                                                              }
                                                           >
                                                             <div className="d-flex align-items-center" style={{display:'flex!important'}}>
                                                              <i className={`flag flag-24 flag-${language.icon}`}/>
                                                              <h5 className="mb-0 ml-2">{language.name}</h5>
                                                            </div>
                                                           </DropdownItem>

                                                             )}


              
                </DropdownMenu>
              </UncontrolledDropdown>

          </Nav>
        </Collapse>
      </Container>
    </Navbar>
  );
}

//export default IndexNavbar;
const mapStateToProps = ({settings}) => {
  const {locale } = settings;
  return { locale }
};

export default connect(mapStateToProps, {switchLanguage})(IndexNavbar);