

import React,{useEffect} from 'react';
import {
    Button,
    Card,
    CardBody,
    CardFooter,
    CardTitle,
    Container,
    Row,
    Col
  } from "reactstrap";
import IntlMessages from 'lngProvider/IntlMessages';
import config from 'config';
  
export default function Comitteee({activity}) {
   // ////console.log("activity :" ,activity);
    const [committeeTypeList, setCommitteeTypeList] = React.useState([]);
    useEffect(() => {
        if(committeeTypeList){ 
            setCommitteeTypeList(activity.committeeTypeList);
        }
    });

    const getFullName = (user)=>{
        return user.title !== '' ? `${user.title} ${user.name} ${user.surname}` :  `${user.name} ${user.surname}`;
    }

  return (
    <>
      <div className="section section-gray text-center" id="comittee">
          <Container>
            <h2 className="title">   <IntlMessages id="committees.title"/></h2>
            <Row>
             {   
             committeeTypeList.map(item=>
                <Col  key={item.type} md={committeeTypeList.length === 1 ? "12" : committeeTypeList.length === 2 ? "6" : committeeTypeList.length === 3 ? "4" : committeeTypeList.length === 4 ? "3" : "2" }>
                <Card className="card-profile card-plain">
               
                  <CardBody>
                      <div className="author text-left" style={{textAlign:'left', marginBottom:'30px'}}>
                        <CardTitle tag="h3" > <IntlMessages id={"committeeType."+item.type}/></CardTitle>
                        {/* <h6 className="card-category">Product Manager</h6> */}
                      </div>
                    
                    {
                        item.userList.map(user=>
                          <div className="row text-center mb-2" key={user.id} style={{display: 'flex', alignItems: 'center', justifyContent: 'flex-start'}}>
                          <div style={{ flexShrink: 0, marginRight:'10px'}}>
                            <img  style={{height:50 , width:50 , border:'2px solid grey'}} alt='Kullanıcı'
                              src={config.userAuthApiUrl + user.profileImageUrl}
                              onError={(e)=>{e.target.onerror = null; e.target.src=config.userAuthApiUrl + config.defaultProfileImageUrl }}
                            />                                
                          </div>
                          <div style={{ maxWidth: 'calc(100% - 70px)', wordWrap: 'break-word' }}>
                            <p className="text-left" key={user.id} style={{ margin: 0 }}>
                              {getFullName(user)}
                            </p>
                          </div>
                        </div>
                        
                            )
                    }
               
                  </CardBody>
                  <CardFooter className="text-center">
         
                  </CardFooter>
                </Card>
              </Col>

                )
                }
               
            </Row>
          </Container>
        </div>
    </>
  );
}
