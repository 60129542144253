
/*eslint-disable*/
import React,{useState,useEffect} from "react";

// reactstrap components
import { Container } from "reactstrap";
import IntlMessages from "lngProvider/IntlMessages";

// core components



function IndexHeader() {
  // const [imageUrl,setImageUrl] = useState("") 
  // const [count, setCount] = useState(1);

 
  // function nextBackground() {
   
  //   setImageUrl(require("assets/img/s"+count+".jpg"))
  //   setCount(count+1)
  //   if(count===11)
  //   setCount(1)

  // }
  // useEffect(() => {
  //   setInterval(nextBackground, 10000 * count);
  // });

  const [index, setIndex] = useState(1);
  const bgImgArray = [

    require("assets/img/bg/bg1.jpg"),
    require("assets/img/bg/bg2.jpg"),
    require("assets/img/bg/bg3.jpg"),
    require("assets/img/bg/bg4.jpg"),
    require("assets/img/bg/bg5.jpg"),
    require("assets/img/bg/bg6.jpg"),

      //  require("assets/img/s1.jpg"),
      //  require("assets/img/s2.jpg"),
      //  require("assets/img/s3.jpg"),
      //  require("assets/img/s4.jpg"),
      //  require("assets/img/s5.jpg"),
      //  require("assets/img/s6.jpg"),
      //  require("assets/img/s7.jpg"),
      //  require("assets/img/s8.jpg"),
      //  require("assets/img/s9.jpg"),
      //  require("assets/img/s10.jpg"),
  ];

  useEffect(() => {
    const timer = setInterval(() => setIndex(i => i + 1), 5000);
    return () => clearInterval(timer);
  }, []);

  const imageUrl = bgImgArray[index % bgImgArray.length];


  return (
    <>
      <div className="page-header section-dark"
     //   id="animated-background"
        style={{
          backgroundImage: `url(${imageUrl})` ,    //  imageUrl // "url(" + require("assets/img/s3.jpg") + ")",
          transition: 'background 5000ms linear'
              }

            }
                    >
        <div className="filter" />
        <div className="content-center">
          <Container>
            <div className="title-brand">
              {/* <h2 className="presentation-title"> <IntlMessages id="activity.onepage.nameEx"/></h2> */}
              <h2 className="presentation-title"> <IntlMessages id="activity.onepage.name"/></h2>


               <div className="fog-low">
                <img alt="..." src={require("assets/img/fog-low.png")} />
              </div> 
               <div className="fog-low right">
                <img alt="..." src={require("assets/img/fog-low.png")} />
              </div> 
            </div>
            <h2 className="presentation-subtitle text-center">
            <IntlMessages id="activity.onepage.subTitle"/>
            </h2>
            <h3 className="presentation-subtitle text-center">
            <IntlMessages id="activity.onepage.message"/>
            </h3>
          </Container>
        </div>
        <div
          className="moving-clouds"
          style={{
            backgroundImage: "url(" + require("assets/img/clouds.png") + ")"
          }}
        />
        <h6 className="category category-absolute">
         {/* Hazırlayan {" "} */}
          <a
            href="https://www.ayk.gov.tr"
            target="_blank"
          >
            {/* <img
              alt="..."
              className="creative-tim-logo"
              src={require("assets/img/creative-tim-white-slim2.png")}
            /> */}
            <p>
          <IntlMessages id="footer.copright"/></p>
          </a>
        </h6>
      </div>
    </>
  );
}

export default IndexHeader;
