import React,{useEffect,useState} from 'react';
import { Card, CardBody, CardTitle, CardText, Button ,Container , FormGroup,
  Label,
  Input} from 'reactstrap';
import IntlMessages from 'lngProvider/IntlMessages';

const FilterActivty = ({listActivity,listActivity2,updateActivityList,color})=> {

  const [activityStatus, setActivityStatus] = React.useState(true);
  const [institution, setInstitution] = React.useState(null);

  useEffect(() => {
  
    //  //console.log("çalıştı")
      onchangeChechBox("activityStatus",activityStatus)
 
    
     },[activityStatus])
     

// const onClickFilterActivity = ()=>{
//   //console.log("filter ", activityStatus, institution);

//   let newList = listActivity;

//   if(institution !== null)
//   newList = listActivity.filter(x=>x.institution === institution);
  
//   if(activityStatus)
//   newList = newList.filter(x=> x.status === 1)

//   updateActivityList(newList)


// } 

const onchangeChechBox = (name,value)=>{
  ////console.log("name value activityStatus",activityStatus);

  if(name==="activityStatus")
  {
    if(value)
    updateActivityList(listActivity.filter(x=> x.status === 1));
    else
    updateActivityList(listActivity2);
  }
}

const onClickClearFilterActivity= ()=>{
  updateActivityList(listActivity2);


 
}
const onChangeInstitution = (value)=>{
  //console.log(value);
  setInstitution(value);
  updateActivityList(listActivity2.filter(x=>x.institution === value))
}

const onChangeText = (event) => {
  //console.log("sonuc : "+event.target.value)
  const text = event.target.value;
  
  if(text!=="")
  {
    let newList = listActivity2.filter(item=>
    
      ( 
        (item.title.toLocaleLowerCase().includes(text.toLocaleLowerCase())) ||
     //   (item.keyword.toLocaleLowerCase().includes(text.toLocaleLowerCase())) ||
        (item.id === Number(text))
      )
      
    ) ;
    
    updateActivityList(newList)

  }
  else
  {
    updateActivityList(listActivity2)


  }
  
};

  return (
    <Container>
    <Card color={color} inverse={true} >
    <CardBody>
        <CardTitle className="text-white"> <IntlMessages id="filteredOptions"/></CardTitle>
        <div className="row">
    
<div className="col-md-3">
{/* <FormGroup check>
        <Label check>
          <Input type="checkbox" />{' '}
Geçmiş etkinlikleri gösterme
          <span className="form-check-sign">
            <span className="check"></span>
        </span>
        </Label>
      </FormGroup> */}
      <FormGroup check  className="mt-3"  >
        <Label check>
          <Input type="checkbox"
          onChange={(e)=>{setActivityStatus(e.target.checked)}}
            checked={activityStatus}
            />{' ' }
         {/* <IntlMessages id="onlyEnableActivity"/> */}
          <span className="form-check-sign">
            <span className="check"></span>
        </span>
        <IntlMessages id="onlyEnableActivity"/>
        </Label>
      </FormGroup>
</div>

<div className="col-md-3">
<FormGroup>
        <Label for="exampleSelect1"> <IntlMessages id="activity.institution"/></Label>
        <Input type="select" name="select" id="exampleSelect1" value={institution} onChange={(e)=>onChangeInstitution(e.target.value)}>
        <option value="AKMB">  AKMB</option>
          <option value="ATAM">ATAM</option>
          <option value="TTK"> TTK </option>
          <option value="TDK"> TDK </option>
        </Input>
      </FormGroup>
</div>
<div className="col-md-3">
  
<FormGroup>
        <Label for="exampleSelectMulti1"> <IntlMessages id="user.table.search"/></Label>
        <Input
          type="text"
          name="searchText"
          id="exampleEmail"
         // placeholder={  <i className="nc-icon nc-layout-11" /> }
          onChange={onChangeText}
          
        />
      </FormGroup>
</div>
<div className="col-md-3">
{/* <Button
                  className="btn-round mr-1"
                  color="danger"
                  onClick={onClickFilterActivity}
                  type="button"
               
                >
                    <IntlMessages id="filter"/>
                </Button> */}
                <Button
                  className="btn-round mr-1 mt-4"
                  color="warning"
                  onClick={onClickClearFilterActivity}
                  type="button"
               
                >
                    <IntlMessages id="stepper.activity.button.reset"/>
                </Button>
</div>
       
   
     </div>

    </CardBody>
</Card>
</Container>
  );
}

export default FilterActivty;